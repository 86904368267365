import React from "react";
import {
  MainSectionWrapper,
  StyledMainImage,
} from "./instantNewachSection.styles";
import straight from "../../../images/straight.svg";
import CtaList, { CtaListProps } from "../../ctaList/ctaList";

const listData: CtaListProps = {
  title: ["Instant Bank ACH", "fund settlement"],
  subTitle: "Fund wallets and process payments instantly, with no-risk.",
  items: [
    {
      title: "Increase revenue",
      subTitle: "Increase payments processed by 10% or more per month.",
      icon: straight,
    },
    {
      title: "Improve customer loyalty",
      subTitle: "Upgrade your customers’ on-boarding and payments experience.",
      icon: straight,
    },
    {
      title: "Up to $25K per day",
      subTitle: "Provide your customers with increased ACH buying power",
      icon: straight,
    },
  ],
  link: {
    text: "See a demo",
    url: "/contact",
  },
};

const InstantNewachSection = () => {
  return (
    <section
      id="instantSettlement"
      className="relative section-wrapper"
      css={MainSectionWrapper}
    >
      <div className="absolute top-0 curves-parent left-full"></div>
      <div className="relative w-full md:pt-26 pt-40 bg-transparent wrapper-parent layout-width">
        <div className="flex md:flex-col-reverse flex-row flex-wrap justify-between h-full lg:mb-20">
          <div className="flex justify-center w-1/2 sm:w-full md:mx-auto">
            <div className="mr-auto transform md:-translate-x-10 -translate-x-14 -translate-y-20 mt-0 md:mt-12 md:mx-auto">
              <StyledMainImage className="h-full w-full transform md:" />
            </div>
          </div>
          <div className="flex flex-col justify-center w-1/2 mx-auto image-parent sm:w-full md:mb-12">
            <CtaList {...listData} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default InstantNewachSection;
