import React, { FC } from "react";
import {
  MainSectionWrapper,
  StyledMainImage,
} from "./indemnificationSection.styles";
import MainImageMobile from "../../../images/payments/Payment_1_Mobile.inline.svg";
import straight from "../../../images/straight.svg";
import CtaList, { CtaListProps } from "../../ctaList/ctaList";

const listData: CtaListProps = {
  title: "Fraud indemnification",
  subTitle:
    "Integrate our SDK, and let us take on full liability for chargebacks and returns.",
  items: [
    {
      title: "Grow revenue faster",
      subTitle: "Comply with NACHA return thresholds",
      icon: straight,
    },
    {
      title: "Pre-fund customer accounts 2-3 days faster!",
      subTitle: "Advanced cash flow prediction models, reduce NSF risk.",
      icon: straight,
    },
    {
      title: "Stop worrying about fraud",
      subTitle: "Reduce sign-up friction to convert more good customers",
      icon: straight,
    },
  ],
};

const IndemnificationSection: FC = () => (
  <section
    id="fraud"
    className="relative section-wrapper"
    css={MainSectionWrapper}
  >
    <div className="absolute top-0 curves-parent left-full md:hidden"></div>
    <div className="relative w-full pt-20 bg-transparent wrapper-parent layout-width">
      <div className="flex flex-row md:justify-center flex-wrap justify-between h-full lg:mb-20">
        <div className="flex justify-center w-1/2 sm:w-full">
          <div className="w-full">
            <CtaList {...listData} />
          </div>
        </div>
        <div className="flex flex-col justify-center w-1/2 mx-auto image-parent sm:w-full md:hidden">
          <div className="mx-auto">
            <StyledMainImage
              className="h-full md:hidden"
              preserveAspectRatio="xMidYMin"
            />
          </div>
        </div>
      </div>
    </div>
    <MainImageMobile className="w-full hidden md:block z-10 relative" />
  </section>
);

export default IndemnificationSection;
