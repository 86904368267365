import { css } from "@emotion/react";

const MainSectionWrapper = css`
  overflow: hidden;
  .wrapper-parent {
    min-height: 753px;
  }
  .title-lg-parent {
    max-width: 500px;
  }
  .title-parent {
    max-width: 520px;
  }
  .btn-parent {
    max-width: 179px;
  }
  .logos-parent {
    max-width: 1070px;
    .gatsby-image-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto auto auto 0;
    }
  }
  .image-parent {
    .gatsby-image-wrapper {
      max-width: 450px;
    }

    svg {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 767.98px) {
    .image-parent svg {
      max-width: 400px;
    }
  }

  @media screen and (max-width: 480px) {
    .image-parent svg {
      max-width: 100%;
    }
  }
`;

export { MainSectionWrapper };
