import * as React from "react";
import Layout from "../components/layout";
import IndemnificationSection from "../components/paymentsSections/indemnificationSection/indemnificationSection";
import InstantCryptoSection from "../components/paymentsSections/instantCryptoSection/instantCryptoSection";
import InstantNewachSection from "../components/paymentsSections/instantNewachSection/instantNewachSection";
import Seo from "../components/seo/seo";
import BlueSection from "../components/blueSetion/blueSection";

const PaymentsPage = () => {
  const textArr = ["Move money", " fast, ", "without risk."];
  return (
    <Layout>
      <Seo title="Payments" pathname="/payments" />
      <main>
        <IndemnificationSection />
        <InstantNewachSection />
        <InstantCryptoSection />
        <BlueSection textArr={textArr} btnText={"Book a demo"} />
      </main>
    </Layout>
  );
};

export default PaymentsPage;
