import styled from "@emotion/styled";
import { css } from "@emotion/react";
import leftCurves from "../../../images/left_curves.svg";
import ach_curves from "../../../images/payments/Payment_2_Mobile.svg";
import MainImage from "../../../images/payments/Payment_2.inline.svg";

const StyledMainImage = styled(MainImage)``;

const MainSectionWrapper = css`
  overflow: hidden;
  .wrapper-parent {
    min-height: 753px;
  }
  .curves-parent {
    background: url(${leftCurves}) center no-repeat;
    width: 1825px;
    height: 609px;
    transform: none;
    left: 0;
    top: 0;
    width: 100%;
    background-position: left;
  }
  .title-lg-parent {
    max-width: 550px;
  }
  .title-parent {
    max-width: 520px;
  }
  .btn-parent {
    max-width: 179px;
  }
  .logos-parent {
    max-width: 1070px;
    .gatsby-image-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto auto auto 0;
    }
  }
  .image-parent {
    .gatsby-image-wrapper {
      max-width: 450px;
    }
  }

  @media screen and (max-width: 767.98px) {
    .image-parent svg {
      max-width: 360px;
    }

    .curves-parent {
      background: url(${ach_curves}) center no-repeat;
      width: 100%;
      height: 600px;
      left: 0;
      top: 34%;
      transform: none !important;
      background-size: cover;
    }
  }

  @media screen and (max-width: 480px) {
    .curves-parent {
      transform: translate(-50%, 80%);
    }
  }
`;

export { MainSectionWrapper, StyledMainImage };
