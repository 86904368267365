import { css } from "@emotion/react";
import styled from "@emotion/styled";
import rightCurve from "../../../images/right_curves.svg";
import MainImage from "../../../images/payments/Payment_1.inline.svg";
import media from "../../breakpoints";

const StyledMainImage = styled(MainImage as any)`
  width: 645px;
  height: 491px;
`;

const MainSectionWrapper = css`
  overflow: visible;
  z-index: 1;
  .wrapper-parent {
    max-height: 595px;
    ${media("md")} {
      max-height: 100%;
    }
  }
  .curves-parent {
    background: url(${rightCurve}) center no-repeat;
    width: 1865px;
    height: 622px;
    transform: translate(-82%, 0%);
  }
  .title-lg-parent {
    max-width: 620px;
  }
  .title-parent {
    max-width: 520px;
  }
  .btn-parent {
    max-width: 179px;
  }
  .logos-parent {
    max-width: 1070px;
    .gatsby-image-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto auto auto 0;
    }
  }
  .image-parent {
    .gatsby-image-wrapper {
      max-width: 450px;
    }
  }

  @media screen and (max-width: 767.98px) {
    .image-parent svg {
      max-width: 480px;
    }

    .curves-parent {
      background-size: (100%, 100%);
      transform: translate(-80%, 50%);
    }
  }

  @media screen and (max-width: 480px) {
    .curves-parent {
      transform: translate(-65%, 70%);
    }
  }
`;

export { MainSectionWrapper, StyledMainImage };
