import React, { FC } from "react";
import { MainSectionWrapper } from "./instantCryptoSection.styles";
import straight from "../../../images/straight.svg";
import MainImage from "../../../images/payments/Payment_3.inline.svg";
import CtaList, { CtaListProps } from "../../ctaList/ctaList";
interface Props {}

const listData: CtaListProps = {
  title: ["Instant Bank ACH to", "crypto on-ramp"],
  items: [
    {
      title: "Instant Access",
      subTitle: "Invest or spend your funds right away.",
      icon: straight,
    },
    {
      title: "Higher Limits",
      subTitle: "Purchase up to $3,000 per day.",
      icon: straight,
    },
    {
      title: "Lower Fees",
      subTitle: "Get more crypto for your money.",
      icon: straight,
    },
  ],
  link: {
    text: "See a demo",
    url: "/contact",
  },
};

const InstantCryptoSection: FC<Props> = () => {
  return (
    <section
      id="instant"
      className="relative section-wrapper"
      css={MainSectionWrapper}
    >
      <div className="relative w-full md:pt-0 pt-40 bg-transparent wrapper-parent layout-width">
        <div className="flex md:flex-col-reverse flex-row-reverse flex-wrap justify-between h-full mb-20">
          <div className="flex justify-center w-1/2 sm:w-full md:mx-auto">
            <MainImage className="h-full" />
          </div>
          <div className="flex flex-col justify-center w-1/2 pr-8 mx-auto image-parent sm:w-full md:pr-0">
            <div className="mx-auto mt-0 md:mt-12 md:transform-none transform -translate-y-8 relative md:mb-12">
              <CtaList {...listData} />
              {/* <LineCurves /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InstantCryptoSection;
